.queue-container{
    font-family: 'Share Tech Mono', monospace;
   
    .add-track-button{
        display: inline-block;

        button{
            border-radius: 5px;
            border: none;
            transform: scale(1.2);
            

            .plus{
             transform: scale(0.9);
            }
            .disc{
                transform: scale(1.1);
                color: rgb(114, 19, 158);
               
            }
        }
    }

    & > p {margin-top: 20px;
        color: white;
        display: inline-block;
        
        }
}

.queue{
    max-width: 100%;
    min-height: 200px;
    max-height: 220px;
     margin: 0;
    background-color: rgba(51, 51, 51, 0.295);
    border-radius: 10px;
    text-align: center;
    position: relative;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    p {
        color: rgb(255, 255, 255); 
     
     }

    img{
    
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 2px 5px;
        transform: scale(0.8);
     
    }

   & > p {
        font-size: 12px;
    }

  } 