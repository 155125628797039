.sidebar {
    height: 100vh;
    position: fixed;
    max-width: 450px;
    z-index: 90;
    left: -50%;
    background-color: rgba(17, 17, 17, 0.705);
    overflow-x: hidden;
    transition: 0.5s;
    padding-right: 10px;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media screen and (max-width: 480px) {
    .sidebar {
      width: 100%;
    }
  }
  
  .backdrop {
    transition: 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 65;
    backdrop-filter: blur(5px);
    overflow: hidden;
  }
  .close{
      position: relative;
      width: 100%;
      text-align: right;
      top: 10px;
      right: 10px;
      color: rgba(190, 190, 190, 0.808);
    
  }
  .close-button{
      transform: scale(1.6);
      transition: 0.3s;
      &:hover{
        cursor: pointer;
        transform: scale(1.8);
    }
  }
 

  .search-field{
width: 100%;
text-align: center;
margin-top: 50px;
padding: 0 30px 0 30px;
input{
  width: 200px;
  height: 35px;
  border-radius: 10px;
  background-color: rgba(146, 146, 146, 0.493);
  color: white;

&:focus{

  outline: solid 3px rgb(36, 180, 108);
  border: transparent;
}
}

  }
  .search-icon
  {
color: rgb(36, 180, 108);
transform: scale(1.7);
margin-left: 20px;

&:hover{
  cursor: pointer;
}
  }

  .search-result{
      position: relative;
      width: 80%;
      left: 10%;
      margin-top: 50px;
      min-height: 100px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none; 
      }
    
      
  }
 
 
  