@import '../Styles/modules'; 

.page{ 
  height: 90vh; 
  width: 100%;
@include background(); 


.card{
  background-color: white;
  width: 80%;
  left:10%;  
  top: 5%; 
  position: relative;

}



}


::-webkit-scrollbar {
    width: 13px;
  
  }
  
  ::-webkit-scrollbar-track {
    background: #5f5f5fa1;
    
  }
  
  
  ::-webkit-scrollbar-thumb {
    background: rgb(27, 131, 100); 
    border-radius: 20px;
    
  }
 