@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.video-container{
    max-width: 100%;
    min-height: 470px;
    margin-top: 20px;
   text-align: center;
  overflow: hidden;
    position: relative;

    p {
        margin-top: 5px;
         color: white;
         text-align: left;
         font-family: 'Share Tech Mono', monospace;
         margin-left: 100px;
         margin-top: 15px;
        
     }
     img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 2px 5px;   
}

}

.video{
   top: 10px;
    width: 80%;
    height: 365px;
    border-radius: 20px;
    position: relative;
    
   
 
}


  @media only screen and (max-width: 638px) {
    .video {
        width: 90%;
        height: 300px;
    }
    .video-container{
        min-height: 350px;

    }
    .video-container p{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
  }

  @media only screen and (max-width: 415px) {
    .video {
        width: 90%;
        height: 220px;
    }
    .video-container{
        min-height: 250px;

    }
    .video-container p{
       
       font-size: 12px;
    }
  }
    

  

.playbox{
    height: 50px;
    width: 50px;
    background-color: rgb(177, 178, 179);
    position: relative;
    background-color: rgb(233, 107, 245);
    
}

.volume{
    width: 100%;
    

.foward{
    background-color: rgba(71, 71, 71, 0.095);
    padding: 5px;
    border-radius: 2px;
    position: absolute;
    color: #9413bb;
    margin-top: 18px;
    margin-left: 15%;
    transform: scale(1.7);
    &:hover{
        cursor: pointer;
    }
    @media only screen and (max-width: 370px) {
        & {
            margin-left: 5%;
        }
      }
}


}