.frame{
    width: 100%;
    height: 100%;
text-align: right;
position: relative;

}

.chat-container{
    height: 75vh;
    max-width: 400px;
    margin-left: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgb(33, 37, 41);
    border-radius: 30px 30px 0 0;
    text-align: left;
    z-index: 35;



    
    
}



@media screen and (max-width: 991px) {
  .frame {
    display: flex;
  justify-content: center;

    height: 80vh;
  }

  .chat-container{
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    
    
  }
}






.message-window{
    width: 95%;
    height: 90%;
    top: 1.5%;
    left: 2.5%;
    margin-bottom: 1.5rem;
    background-color: #01020D;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23262965'/%3E%3Cstop offset='1' stop-color='%2301020D'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%238984FF' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%238984FF' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.14'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
background-attachment:fixed;
background-size: cover;
    position: relative;
    border-radius: 30px 30px 0 0;
    overflow-y: scroll;
  

    @media only screen and (max-height: 760px) {
      & {
        height: 89%;
      }
    }
    @media only screen and (max-height: 650px) {
      & {
        height: 88%;
      }
    }
    @media only screen and (max-height: 570px) {
      & {
        height: 87%;
      }
    }

    @media only screen and (max-height: 550px) {
      & {
        height: 85%;
      }
    }

    @media only screen and (max-height: 475px) {
      & {
        height: 82%;
      }
    }
    @media only screen and (max-height: 395px) {
      & {
        min-height: 80%;
      }
    }
    @media only screen and (max-height: 445px) {
      & {
        height: 78%;
      }
    } 

    // jako lose rjesenje, ali za sad nikako drugacije ne mogu da napravim da kad se koristi tastatura na telefonu da input ne ode ispod footera 

}

  ::-webkit-scrollbar {
    width: 13px;
    
    
  }



.message-window::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 25px;
}
  
  .message-window::-webkit-scrollbar-track {
    background: transparent
    
  }
  
  .message-window::-webkit-scrollbar-thumb {
    background: rgba(43, 169, 201, 0.664);
    border-radius: 20px;
    
  }
  


