@import '../../Styles/modules'; 
.backdrop{

    transition: 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 60;
    backdrop-filter: blur(5px);
    overflow: hidden;


.panel{
    width: 50%;
    left: 25%;
    top: 10vh;
    height: 50vh;
    position: absolute;
    border-radius: 5px;
    z-index: 1;
    background: inherit;
    overflow: hidden;
    text-align: center;
  background-color: rgba(32, 32, 32, 0.836);


  @media screen and (max-width: 1000px) {
    & {
        width: 80%;
        left: 10%;
        
    }
  }

  @media screen and (max-width: 450px) {
    & {
        width: 100%;
        left: 0;
        height: 100%;
        top: 0;
    }
  }




  .close{
    position: absolute;
    right: 15px;
    transform: scale(1.5);
    color: rgba(190, 190, 190, 0.808);
    &:hover{
        cursor: pointer;
    }
  
}
 

      .container {
          
          top: 25px;
        position: relative;
        width: auto;

        &:hover .image {
            opacity: 0.3;
          }
        &:hover .middle {
            opacity: 1;
          }
      


      img{
          border-radius: 0;
         border-radius: 10px;
          transform: scale(1.2);
          transition: .5s ease;
          backface-visibility: hidden;
          opacity: 1;
          width: 96px;
          height: 96px;
           
       

      }
      .middle {
        transition: .5s ease;
        width: 116px;
        opacity: 0;
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        overflow: hidden;

        .text {
            background-color: #000000b4;
            color: white;
            font-size: 14px;
            padding-bottom: 11px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            
           
          }
          .text:hover{
              cursor: pointer;
          }

      }
 

    }

}


@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.inputs{
max-width: 400px;
position: relative;
margin: 70px auto;
text-align: center;
height: 80%;
p{
    color: white;
    background-color: rgba(83, 83, 83, 0.568);
    padding: 10px;
    width: 90%;
    left: 5%;
    font-family: 'Share Tech Mono', monospace;
    
    
   
}
svg{
    color: white;
    display: inline;
    margin-left: 15px;
    &:hover{
        cursor: pointer; 
    }
    
}




} 






}    



 
  

  
 