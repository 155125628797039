@import '../Styles/modules'; 

@mixin inputField {
  width: 76%;
  color: rgb(38, 50, 56);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 50px;
  text-align: center;
  margin-bottom: 27px;
  font-family: "Ubuntu", sans-serif;
}

.main {
    background-color: #ffffff;
    min-width: 350px;
    max-width: 500px;
    height: 600px;
    margin: auto;
    top: 5rem;
    bottom: 10rem;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.384);
    position: relative;
 
  
  }

  @media screen and (max-width: 480px) {
    .main{ 
        border-radius: 0;
    }
  }


  .sign {
    padding-top: 40px;
    color: rgb(28, 190, 142);
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    font-size: 23px;
    text-align: center;
    z-index: 70;
  }
  .email {
    @include inputField();
    &:focus {
      border: 2px solid rgba(0, 0, 0, 0.18) !important;
    }

  }
 .form {
    padding-top: 40px;
    @include noselect;
    hr{
      width: 70%;
      margin-left: 15%;
  }
  }

  .pass {
    @include inputField();
    &:focus {
      border: 2px solid rgba(0, 0, 0, 0.18) !important;
    }
  }
  
.center-wrap{
    width: 100%;
    text-align: center;

    p {
      margin-top: 30px;
      font-family: "Ubuntu", sans-serif;
  }
}

.sign-in {
    margin-top: 5px;
    cursor: pointer;
    border-radius: 5em;
    color: #fff;
    background:#155e80; 
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: "Ubuntu", sans-serif;
    position: relative;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
    font-weight: 700;
    @include noselect;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, 0.644);
    }
  }

  .back-arrow-container{
      width: 100%;
      left: 40px;
      top: 30px;
      z-index: 100;
      position: relative;
      .back-icon{
        transform: scale(1.3);
       &:hover{
         cursor: pointer;
     
     }
       
       }

  }
  
