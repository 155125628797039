.icon{
    color: rgb(27, 131, 100);
    transform: scale(1.2);

    @media screen and (max-width: 615px) {
         &{
           transform: scale(1); 
           color: rgb(27, 131, 100);
        }
      }
}
.container{
    max-width: 168px;
    min-width: 80px;

}
body::-webkit-scrollbar-track {
  background: rgb(33, 37, 41);
  
}