@import '../Styles/modules'; 
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.page{
    height: 90vh;
    @include background();
    .panel{
        width: 80%;
        left: 10%;
        top: 5%;
        height: 80vh;
        position: relative;
        border-radius: 5px;
        z-index: 1;
        background: inherit;
        overflow: hidden;
        text-align: center;
      
    
        &::before {
            content: "";
            position: absolute;
            background: inherit;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: inset 0 0 2000px rgba(25, 117, 79, 0.753);
            filter: blur(15px);
            margin: -5px;
          }


          ul{
            list-style-type:none;
            font-family: 'Share Tech Mono', monospace;
            letter-spacing: -0.5px;
            li{
              font-size: 20px;
              margin-top: 15px;
              color: rgb(189, 189, 189);
            }
          }
        
        }

























} 
    