@mixin noselect  {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin background {
    background: url("https://cdn.gamedevmarket.net/wp-content/uploads/20200418003230/5be96f259d13c8fddd4ce65a5bcc7b9e-1400x788.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}