.section{
    position: fixed;
    top: 30vh;
    left: 0;
    width: 100vw;
    height: 90vh;
   overflow: hidden;
    animation: animateBg 120s linear infinite;
    z-index: 0;

}
@keyframes animateBg {
    0%,100%
    {
        transform:scale(1);
    }
    50%
    {
        transform:scale(1.2);
    }
}
.section > span{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1)  ;
    animation:  animate 10s linear infinite;
    z-index: 1;
&::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
}


}

@keyframes animate {
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }
    70%
    {
        opacity: 1;

    }
    100%
    {
        transform: rotate(315deg) translateX(-1500px);
        opacity: 0;

    }
    
}
.section > span:nth-child(1){
    top: 0;
    right: 0;
    left:initial;
    animation-delay:0 ;
    animation-duration: 15s;
}

.section > span:nth-child(2){
    top: 0;
    right: 80px;
    left:initial;
    animation-delay:0.2s;
    animation-duration: 10s;
}

.section > span:nth-child(3){
    top: 80px;
    right: 0px;
    left:initial;
    animation-delay:0.4s ;
    animation-duration: 7s;
}

.section > span:nth-child(4){
    top: 0;
    right: 180px;
    left:initial;
    animation-delay:0.6s;
    animation-duration: 12.5s;
}

.section > span:nth-child(5){
    top: 0;
    right: 400px;
    left:initial;
    animation-delay:0.8s;
    animation-duration: 9.5s;
}

.section > span:nth-child(6){
    top: 0;
    right: 600px;
    left:initial;
    animation-delay:1s ;
    animation-duration: 17s;
}
.section > span:nth-child(7 ){
    top: 300px;
    right: 0px;
    left:initial;
    animation-delay:1s ;
    animation-duration: 10.75s;
}

.section > span:nth-child(8){
    top: 0px;
    right: 700px;
    left:initial;
    animation-delay:1.4s ;
    animation-duration: 10.25s;
}

.section > span:nth-child(9){
    top: 0px;
    right: 1000px;
    left:initial;
    animation-delay:0.75s ;
    animation-duration: 15.25s;
}

.section > span:nth-child(10){
    top: 0px;
    right: 1000px;
    left:initial;
    animation-delay:2.75s ;
    animation-duration: 22.25s;
}

  