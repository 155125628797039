.emoji-icon{
    @media only screen and (max-width: 990px) {
        & {
          margin-top: -10px;
        }
      }

&:hover{
    cursor: pointer;
}


}