
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');


.dj-room{
    text-decoration: none;
 margin-left: 20px;
 &:hover{
    cursor: pointer;

}
}
.navBar{
    min-height: 5vh;
    font-family: 'Share Tech Mono', monospace;
  z-index: 61;


  @media screen and (max-width: 991px) {
    & {
        z-index: 51;
    }
  }

}
.login{
    position: absolute;
    right: 0;
    font-size: 17px;
 
    
}

.login-text{
     color: rgba(255,255,255) !important;
    font-weight: 500;
    &:hover{
        color: rgba(156, 156, 156, 0.904) !important;
    }
}

.login-icon{
   margin-right: 5px ;
   transform: scale(1.2);
    color: rgba(41, 145, 113, 0.823); 
}

.logout-text{
    color: rgba(41, 145, 113, 0.623) !important;
    font-weight: 500;
    &:hover{
        color: rgb(41, 138, 109)!important;
      
    }
}

@media screen and (max-width: 991px) {
    .login{
        position: relative;
        margin-left: 5px;
    
  
    }
  }