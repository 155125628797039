@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.frame {
    z-index: 100;
    min-width: 300px;
    max-width: 350px;
    min-height: 250px;
    background-color: rgb(49, 49, 49);
    border-radius: 10px;
    position: fixed;
    top: 500px;
    left: 0px;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 480px) {
        &{ 
            top: 50vh;
        }
      }
      p{
        color: white; 
        top: 20px;
        position: relative;
        padding: 10px 30px 10px 30px;
        text-align: center;
        background-color: rgba(131, 131, 131, 0.445);
        font-family: 'Share Tech Mono', monospace;
    }

    .center{
        width: 100%;
        text-align: center;
        height: auto;
        position: absolute;
        bottom: 20px;

        button{
            width: 100px;
            height: 45px;
            position: relative;
            border-radius: 10px;
            border: none;
            color: white;
            font-family: 'Share Tech Mono', monospace;
            
            }
        
    }
      
    
      
    }




   




  .close{
    position: relative;
    width: 100%;
    text-align: right;
    top: 10px;
    right: 15px;
    color: rgba(190, 190, 190, 0.808);
    svg {
        transform: scale(1.7);
    
        &:hover{
            cursor: pointer;
            transform: scale(1.8);
        }
    }
    
}


 