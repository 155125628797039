.content{
   p{
       display: inline; 
       padding: 0 5px; 
       color: rgb(173, 173, 173);
    }


    width: 100%;

}