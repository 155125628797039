@import '../Styles/modules'; 
.music{
    width: 90%;
    margin-left: 5%;
    min-height: 90vh;
    position: relative;
  
  }
  .page {
  @include background(); 


  
   }   
  .content{
    min-height: 90vh;
   
  }
  .sb-visible{
    overflow-x: hidden;
    overflow-y: scroll !important;
    
  }
 
  

 