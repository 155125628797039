
.image-container{
    width: 70%;
    left: 15%;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    &:hover{
        cursor: pointer;
    }
    img{
        width: 90%;
        height: auto;
        border-radius: 10px;
       position: relative;
       border: 1px solid rgba(122, 122, 122, 0.171);
      
    }
    p {
        color: white;
        position: relative;


        
    }
}


