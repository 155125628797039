
@import '../Styles/modules';
.page{  
    min-height: 90vh;
    @include background(); 
}

@media screen and (max-width: 480px) {
    .authentication {
        padding-bottom: 55vh;
    }
  }

